@import '../../scss/variables';
@import '../../scss/functions/to-rem';

.single-card-technology {
    display: flex;
    height: to-rem(373);
    width: to-rem(373);
    border-radius: 4px;
    background-image: url('../../assets/img/web/card-bg.png');

    @media screen and (max-width: 1439px) {
        height: to-rem(228);
        width: to-rem(279);
    }

    @media screen and (max-width: 833px) {
        width: to-rem(228);
    }

    .single-card-technology-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        margin: 0 to-rem(40) to-rem(40);

        @media screen and (max-width: 1439px) {
            margin: 0 to-rem(23) to-rem(24);
        }

        .single-card-technology-svg {
            margin-bottom: to-rem(24);

            img {
                @media screen and (max-width: 1439px) {
                    width: to-rem(40);
                    height: to-rem(40);
                }
            }
        }

        .single-card-technology-title {
            margin-bottom: to-rem(8);

            @media screen and (max-width: 1439px) {
                font-family: Lexend, sans-serif;
                font-weight: 400;
                font-size: to-rem(14);
                line-height: to-rem(20);
            }

            @media screen and (max-width: 833px) {
                font-size: to-rem(12);
            }
        }

        .single-card-technology-description {
            h6 {
                text-align: start;

                @media screen and (max-width: 1439px) {
                    font-family: Lexend, sans-serif;
                    font-weight: 600;
                    font-size: to-rem(16);
                    line-height: to-rem(24);
                }

                @media screen and (max-width: 833px) {
                    font-family: Metropolis, sans-serif;
                }
            }
        }
    }
}
