@mixin project-blob-1 {
    content: '';
    background: radial-gradient(
        65.67% 65.67% at 41.2% 34.33%,
        #7c1dc9 0%,
        #d171ce 100%
    );
    position: absolute;
    width: 671.2px;
    height: 671.74px;
    top: 146.97px;
    left: 1189.49px;
    opacity: 0.3;
    filter: blur(204.683px);
    border-radius: 1227.47px;
    transform: matrix(-0.64, -0.77, 0.63, -0.78, 0, 0);
    z-index: -1;

    @media screen and (max-width: 1439px) {
        width: 388.74px;
        height: 389.05px;
        top: 136.91px;
        left: 757.5px;
        opacity: 0.3;
        filter: blur(112.754px);
        border-radius: 710.912px;
    }

    @media screen and (max-width: 833px) {
        width: 369.18px;
        height: 369.48px;
        top: 159.15px;
        left: 58.09px;
        opacity: 0.3;
        filter: blur(154.683px);
        border-radius: 1227.47px;
    }
}
