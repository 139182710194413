@import '../../scss/variables';
@import '../../scss/functions/to-rem';

.footer {
    margin: 0 auto;
    max-width: to-rem(1200);
    padding: 0 to-rem(40);

    .line {
        display: inline-block;
        width: 100%;
        border-top: 1px solid $gray;
    }

    .footer-container {
        display: grid;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 2fr 0.8fr 1fr 1fr 0.8fr 1.2fr 2fr;
        row-gap: to-rem(29);
        height: 100%;
        justify-items: center;
        align-items: center;
        padding: to-rem(40) 0 to-rem(40) 0;

        p {
            font-size: to-rem(16);
        }

        p:not(:last-child):hover {
            cursor: pointer;
        }

        .item-0 {
            grid-area: 2 / 1 / 3 / 8;
        }

        .item-1 {
            grid-area: 1 / 1 / 2 / 2;
            justify-self: start;
        }

        .item-2 {
            grid-area: 1 / 2 / 2 / 3;
        }

        .item-3 {
            grid-area: 1 / 3 / 2 / 4;
        }

        .item-4 {
            grid-area: 1 / 4 / 2 / 5;
        }

        .item-5 {
            grid-area: 1 / 5 / 2 / 6;
        }

        .item-6 {
            grid-area: 1 / 6 / 2 / 7;
        }

        .item-7 {
            grid-area: 1 / 7 / 2 / 8;
            justify-self: end;
            align-self: center;

            img:hover {
                cursor: pointer;
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .footer {
        .footer-container {
            column-gap: to-rem(8);
            row-gap: to-rem(18);
            grid-template-columns: 1.5fr 1fr 1fr 1fr 0.8fr 1.3fr 1.5fr;
            padding-top: to-rem(26);

            p {
                font-size: to-rem(14);
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .footer {
        .footer-container {
            grid-template-columns: 1.2fr 0.8fr 1fr 1fr 0.8fr 1.3fr 1fr;

            p {
                font-size: to-rem(14);
            }
        }
    }
}

@media screen and (max-width: 834px) {
    .footer {
        padding: 0 to-rem(20);

        .footer-container {
            grid-template-rows: repeat(4, 1fr);
            grid-template-columns: 1fr 1fr 2fr 1fr 1fr 1fr;
            justify-items: start;
            margin: 0 auto;
            max-width: to-rem(375);
            padding: to-rem(24) 0 to-rem(40);

            p {
                font-size: 16px;
            }

            .item-2 {
                grid-area: 1 / 1 / 2 / 4;
            }

            .item-5 {
                grid-area: 1 / 4 / 2 / 7;
            }

            .item-3 {
                grid-area: 2 / 1 / 3 / 4;
            }

            .item-6 {
                grid-area: 2 / 4 / 3 / 7;
            }

            .item-4 {
                grid-area: 3 / 1 / 4 / 4;
            }

            .item-7 {
                grid-area: 3 / 4 / 4 / 7;
                justify-self: start;
            }

            .item-1 {
                grid-area: 4 / 1 / 5 / 1;
            }

            .item-0 {
                grid-area: 4 / 2 / 5 / 7;
                align-self: center;
                justify-self: center;
            }
        }
    }
}

@media screen and (max-width: 405px) {
    .footer {
        .footer-container {
            p {
                font-size: to-rem(14);
            }

            .item-0 {
                font-size: to-rem(13);
            }
        }
    }
}
