@mixin button-nav {
    height: unset;
    padding: to-rem(8) to-rem(24);

    @media screen and (max-width: 835px) {
        display: none;

        /* stylelint-disable-next-line */
        & ~ .button__linear-background {
            display: none;
        }
    }
}
