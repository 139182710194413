@import '../../scss/variables';
@import '../../scss/functions/to-rem';

.contact-us-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: to-rem(80);
    padding-bottom: to-rem(80);

    h3 {
        text-align: center;
        padding-bottom: to-rem(40);
        color: $purple;

        span {
            color: $white;
        }

        @media screen and (max-width: 1439px) {
            font-size: to-rem(34);
            line-height: to-rem(40);
        }
    }

    .contact-us-desc {
        max-width: 870px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p {
            text-align: center;
            line-height: to-rem(24);
            padding: 0 to-rem(40) to-rem(40) to-rem(40);
        }
    }

    form {
        width: 708px;
        display: flex;
        flex-direction: column;
        font-family: Lexend, sans-serif;
        padding-top: to-rem(47);
        padding-bottom: to-rem(80);

        .foo {
            width: 420px;
        }

        .label {
            line-height: to-rem(24);
            padding-bottom: to-rem(12);
        }

        input {
            box-sizing: border-box;
            width: 100%;
            padding: to-rem(12);
            font-family: Lexend, sans-serif;
            line-height: to-rem(24);
            color: $white;
            background: #1d1c22;
            border: 1px solid #817c8a;
            border-radius: 4px;
        }

        .name-and-address-container {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: to-rem(28);

            .input-container {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }
        }

        .message-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-top: to-rem(28);
            padding-bottom: to-rem(48);

            textarea {
                box-sizing: border-box;
                width: 100%;
                padding: to-rem(12);
                background: #1d1c22;
                border: 1px solid #817c8a;
                border-radius: 4px;
                color: $white;
            }
        }

        input:-webkit-autofill,
        textarea:-webkit-autofill,
        select:-webkit-autofill {
            box-shadow: 0 0 0 1000px #1d1c22 inset !important;
            -webkit-text-fill-color: white !important;
            background-clip: content-box !important;
        }
    }
}

@media screen and (max-width: 834px) {
    .form {
        width: 80%;
    }
}

@media screen and (max-width: 768px) {
    .contact-us-container {
        h3,
        span {
            font-size: to-rem(34);
            line-height: to-rem(40);
        }

        form {
            width: 80%;

            .name-and-address-container {
                grid-template-columns: repeat(1, 1fr);
            }
        }
    }
}

@media screen and (max-width: 460px) {
    .form {
        .foo {
            width: 100%;
        }
    }
}
