.main-nav {
    &__toggler {
        display: none;
        position: relative;
        width: to-rem(46);
        height: to-rem(42);
        border: 1px solid transparent;
        border-radius: 4px;
        background: url('../../assets/img/mobile/hamburger_menu.svg') no-repeat
            center center/cover;

        &:not(.collapsed) {
            background: url('../../assets/img/mobile/modal_close.svg') no-repeat
                center center/cover;
            border: none;
        }
    }
}

@media screen and (max-width: 835px) {
    .main-nav {
        &__toggler {
            display: block;
        }
    }
}
