@mixin button-nav-text {
    background: transparent;
    height: unset;
    padding: to-rem(0) to-rem(0);
    border-bottom: solid 1px transparent;
    transition: border 0.3s ease-out;

    @media screen and (max-width: 833px) {
        border-bottom: none;
    }

    /* stylelint-disable-next-line */
    & ~ .button__linear-background {
        background-image: none;
    }

    &:hover {
        border-bottom: 1px solid $green;
        border-radius: 0;
        color: $green;
        transition: border 0.3s ease-out;

        @media screen and (max-width: 833px) {
            border-bottom: none;
        }
    }
}
