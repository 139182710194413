@mixin project-blob-2 {
    content: '';
    background: $green;
    opacity: 0.37;
    position: absolute;
    width: 243.68px;
    height: 247.03px;
    left: 748px;
    top: 242px;
    transform: matrix(-0.71, -0.7, 0.71, -0.7, 0, 0);
    z-index: -1;
    filter: blur(159.5px);

    @media screen and (max-width: 1439px) {
        width: 141.13px;
        height: 143.07px;
        left: 433px;
        top: 140px;
        filter: blur(92.3771px);
    }

    @media screen and (max-width: 833px) {
        width: 243.68px;
        height: 247.03px;
        left: 267px;
        top: 242px;
        filter: blur(159.5px);
    }
}
