// colors

$purple: #5c34b2;
$pink: #d171ce;
$green: #46f3b5;
$white: #eef0ec;
$black70: #24232f;
$black90: #17171f;
$black100: #11111e;
$gray: #a9a5af;
$button-gradient: linear-gradient(76.96deg, #5f27bd 18.8%, #46f3b5 81.2%);
