@mixin button-secondary {
    background: transparent;
    color: $white;
    border: 1px solid $white;
    transition: border 0.3s ease-out;

    /* stylelint-disable-next-line */
    & ~ .button__linear-background {
        background-image: none;
    }

    &:hover {
        color: $green;
        border: 1px solid $green;
        transition: color 0.3s ease-out;
    }

    @media screen and (max-width: 833px) {
        width: 100%;
    }
}
