@import '../../scss/variables';
@import '../../scss/functions/to-rem';

.about-container {
    max-width: to-rem(1100);
    margin: 0 auto;
    padding: to-rem(80) to-rem(30);

    .about-title-container {
        display: flex;
        justify-content: center;

        .about-title {
            font-weight: 700;
            font-size: to-rem(48);
            line-height: to-rem(56);
            margin-bottom: to-rem(40);
            color: $white;

            .about-title-span {
                color: $green;
            }
        }
    }

    .about-desc-container {
        display: flex;
        justify-content: space-evenly;

        .about-divider {
            border: 1px solid #807b8a80;
        }

        .about-desc {
            text-align: left;
            max-width: to-rem(299);
            padding-bottom: to-rem(8);

            .about-desc-title {
                font-weight: 600;
                font-size: to-rem(20);
            }

            .about-desc-text {
                font-size: to-rem(16);
                font-weight: 400;
                line-height: to-rem(24);
            }
        }
    }
}

@media screen and (max-width: 1439px) {
    .about-container {
        .about-title-container {
            .about-title {
                font-weight: 700;
                font-size: to-rem(34);
                line-height: to-rem(40);
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .about-container {
        .about-desc-container {
            flex-direction: column;
            align-items: center;
            gap: to-rem(24);

            .about-divider {
                width: to-rem(330);
            }

            .about-desc {
                text-align: center;
                width: to-rem(330);
            }
        }
    }
}

@media screen and (max-width: 375px) {
    .about-container {
        .about-desc-container {
            .about-divider {
                width: 136px;
            }
        }
    }
}
