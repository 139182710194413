@import '../functions/';
@import '../variables';

h1 {
    font-family: 'Metropolis Bold', sans-serif;
    font-size: to-rem(72);
    line-height: to-rem(80);
    font-weight: 700;
    color: $white;
}

h2 {
    font-family: 'Metropolis Bold', sans-serif;
    font-size: to-rem(60);
    line-height: to-rem(72);
    font-weight: 700;
    color: $white;
}

h3 {
    font-family: 'Metropolis Bold', sans-serif;
    font-size: to-rem(48);
    line-height: 56px;
    font-weight: 700;
    color: $green;
}

h4 {
    font-family: 'Metropolis Bold', sans-serif;
    font-size: to-rem(40);
    line-height: to-rem(48);
    font-weight: 700;
    color: $white;
}

h5 {
    font-family: 'Metropolis Bold', sans-serif;
    font-size: to-rem(34);
    line-height: to-rem(40);
    font-weight: 700;
    color: $pink;
}

h6 {
    font-family: 'Metropolis SemiBold', sans-serif;
    font-size: to-rem(24);
    line-height: to-rem(32);
    font-weight: 700;
    color: $white;
}

.h7 {
    font-family: 'Metropolis Regular', sans-serif;
    font-size: to-rem(15);
    line-height: to-rem(24);
    font-weight: 500;
    color: $white;
}

p {
    font-family: Lexend, sans-serif;

    @media screen and (max-width: 375px) {
        font-size: to-rem(14);
    }
}
