@import '../../scss/variables';
@import './project-blob-1';

// @import './project-blob-2';

.page-layout {
    background-color: $black90;
    color: $white;
    min-height: 4500px;
    height: min-content;
    overflow: hidden;
    position: relative;
    z-index: 0;

    &::before {
        @include project-blob-1;
    }

    // &::after {
    //     @include project-blob-2;
    // }

    @media screen and (max-width: 1439px) {
        min-height: 3563px;
    }

    @media screen and (max-width: 833px) {
        min-height: 4950px;
    }
}
