@import './scss/typography/';

body {
    margin: 0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}
