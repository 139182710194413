@import '../../scss/variables';
@import '../../scss/functions/to-rem';

.spec-container {
    max-width: to-rem(1200);
    margin: 0 auto;
    color: $white;
    font-weight: 700;

    .spec-title {
        margin-top: to-rem(104);
        text-align: center;

        h2 {
            font-size: to-rem(48);
            line-height: to-rem(56);
            margin-bottom: to-rem(40);
        }

        span {
            color: $pink;
        }
    }

    .spec-cards {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-around;
        margin-bottom: to-rem(116);

        .single-card {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: left;

            h3 {
                font-weight: 700;
                font-size: to-rem(60);
                line-height: to-rem(72);
                padding-right: to-rem(16);
                color: $white;
            }

            p {
                font-weight: 600;
                font-size: to-rem(20);
                line-height: to-rem(24);
                color: $gray;
                width: min-content;
            }
        }

        .border {
            height: to-rem(48);
            width: to-rem(1);
            background-color: $gray;
            opacity: 0.5;
        }
    }
}

@media screen and (max-width: 1439px) {
    .spec-container {
        padding: 0 40px;

        .spec-title {
            h2 {
                font-size: to-rem(34);
                line-height: to-rem(40);
                margin-bottom: to-rem(40);
            }
        }

        .spec-cards {
            .single-card {
                h3 {
                    font-weight: 700;
                    font-size: to-rem(34);
                    line-height: to-rem(40);
                }

                p {
                    font-weight: 400;
                    font-size: to-rem(14);
                    line-height: to-rem(20);
                }
            }
        }
    }
}

@media screen and (max-width: 834px) {
    .spec-container {
        margin: 0 to-rem(72);
        padding: 0;

        .spec-cards {
            flex-direction: row;
            flex-wrap: wrap;
            gap: to-rem(16);

            .single-card {
                flex: 1 1 calc(50% - 16px); // Dvi kortelės vienoje eilutėje mažesniems ekranams

                h3 {
                    justify-self: center;
                    align-self: center;
                    padding: 0;
                }

                p {
                    text-align: left;
                }
            }
        }

        .border {
            display: none;
        }
    }
}

@media screen and (max-width: 510px) {
    .spec-container {
        margin: 0 to-rem(20);

        .spec-cards {
            flex-direction: row;
            flex-wrap: wrap;
            gap: to-rem(16);

            .single-card {
                flex: 1 1 calc(50% - 16px); // Dvi kortelės vienoje eilutėje mobiliuose įrenginiuose
                justify-content: flex-start;

                p {
                    text-align: left;
                }
            }
        }
    }
}
