@import '../../scss/variables';
@import '../../scss/functions';
@import './project-blob-1';
@import './project-blob-2';

.our-projects {
    min-height: to-rem(1136);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 0;

    @media screen and (max-width: 1439px) {
        min-height: to-rem(760);
    }

    @media screen and (max-width: 833px) {
        min-height: to-rem(1011);
        overflow: hidden;
    }

    &__inner-container {
        width: to-rem(1200);
        margin: 0 0 to-rem(80);
        position: relative;

        @media screen and (max-width: 1439px) {
            width: to-rem(738);
            margin: 0 0 to-rem(56);
        }

        @media screen and (max-width: 833px) {
            // temp solution for button width, later a layout component should be added and it would fix it(?)

            width: calc(100% - to-rem(48));
            margin: 0 to-rem(24) to-rem(56);
        }

        &::before {
            @include project-blob-1;
        }

        &::after {
            @include project-blob-2;
        }
    }

    &__title {
        margin: to-rem(56) 0 to-rem(40);
        color: $white;
        text-align: center;

        & > span {
            color: $pink;
        }

        @media screen and (max-width: 1439px) {
            font-weight: 700;
            font-size: to-rem(34);
            line-height: to-rem(40);
        }
    }

    &__cards-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: to-rem(40);

        @media screen and (max-width: 833px) {
            grid-template-columns: 1fr;
        }
    }

    &__button-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: to-rem(40);

        @media screen and (max-width: 1439px) {
            margin-top: to-rem(24);
        }

        @media screen and (max-width: 833px) {
            margin-top: to-rem(32);
        }
    }
}
