@mixin project-blob-1 {
    content: '';
    background: $purple;
    position: absolute;
    width: 243.68px;
    height: 247.03px;
    left: 995px;
    top: 253px;
    filter: blur(183.5px);
    transform: matrix(-0.71, -0.7, 0.71, -0.7, 0, 0);
    z-index: -1;

    @media screen and (max-width: 1439px) {
        width: 141.13px;
        height: 143.07px;
        left: 576px;
        top: 147px;
        filter: blur(106.277px);
    }

    @media screen and (max-width: 833px) {
        width: 243.68px;
        height: 247.03px;
        top: 381px;
        left: -29px;
        filter: blur(183.5px);
    }
}
