@import 'https://fonts.googleapis.com/css2?family=Lexend:wght@400;600;700&display=swap';

@font-face {
    font-family: 'Metropolis Black';
    src: url('../../fonts/Metropolis-Black.otf') format('opentype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis Black Italic';
    src: url('../../fonts/Metropolis-BlackItalic.otf') format('opentype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Metropolis Bold';
    src: url('../../fonts/Metropolis-Bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis Bold Italic';
    src: url('../../fonts/Metropolis-BoldItalic.otf') format('opentype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Metropolis ExtraBold';
    src: url('../../fonts/Metropolis-ExtraBold.otf') format('opentype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis ExtraBold Italic';
    src: url('../../fonts/Metropolis-ExtraBoldItalic.otf') format('opentype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Metropolis ExtraLight';
    src: url('../../fonts/Metropolis-ExtraLight.otf') format('opentype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis Light';
    src: url('../../fonts/Metropolis-Light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis Light Italic';
    src: url('../../fonts/Metropolis-LightItalic.otf') format('opentype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Metropolis Medium';
    src: url('../../fonts/Metropolis-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis Medium Italic';
    src: url('../../fonts/Metropolis-MediumItalic.otf') format('opentype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Metropolis Regular';
    src: url('../../fonts/Metropolis-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis Regular Italic';
    src: url('../../fonts/Metropolis-RegularItalic.otf') format('opentype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Metropolis SemiBold';
    src: url('../../fonts/Metropolis-SemiBold.otf') format('opentype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis SemiBold Italic';
    src: url('../../fonts/Metropolis-SemiBoldItalic.otf') format('opentype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Metropolis Thin';
    src: url('../../fonts/Metropolis-Thin.otf') format('opentype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis Thin Italic';
    src: url('../../fonts/Metropolis-ThinItalic.otf') format('opentype');
    font-weight: 100;
    font-style: italic;
}
