@import '../../scss/variables';
@import '../../scss/functions';

.project-card {
    $this: &;

    background: $black70;
    backdrop-filter: blur(58px);
    border-radius: 5px;
    height: auto; // Automatinis aukštis
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding-bottom: to-rem(20); // Papildomas tarpas apačioje tekstui
    margin-bottom: to-rem(20); // Tarpas tarp kortelių

    &__image {
        width: 100%;
        height: auto; 
        object-fit: contain; 
        margin-bottom: to-rem(10); // Tarpas po paveikslėliu

        @media screen and (max-width: 1439px) {
            margin-bottom: to-rem(15); 
        }
    }

    &__text-container {
        width: 100%;
        text-align: center; 
        margin-top: to-rem(10); // Sumažintas tarpas tarp paveikslėlio ir teksto

        @media screen and (max-width: 1439px) {
            & h6 {
                font-size: to-rem(14); 
            }
        }

        @media screen and (max-width: 833px) {
            & h6 {
                font-size: to-rem(16); 
            }

            & p {
                font-size: to-rem(14); 
            }
        }
    }

    &__with-project {
        display: flex;
        flex-direction: column;
        justify-content: flex-start; 
        width: 100%; 

        & > #{$this}__text-container {
            margin: to-rem(10) 0 0 0; // Tarpas tarp paveikslėlio ir teksto
            text-align: center; 
            position: static; 
        }
    }
}

// Didesniam ekrano plotui
@media screen and (min-width: 1025px) {
    .project-card {
        height: auto; // Išsaugoti automatinį aukštį
    }
}
