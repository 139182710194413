@import '../../scss/variables';
@import '../../scss/functions/to-rem';

.carousel {
    min-height: to-rem(701);
    max-width: to-rem(1440);
    margin: 0 auto;
    padding-top: to-rem(80);

    @media screen and (max-width: 1439px) {
        min-height: to-rem(492);
        padding-top: to-rem(56);
    }

    @media screen and (max-width: 833px) {
        min-height: to-rem(368);
        margin-bottom: to-rem(56);
    }

    @media screen and (max-width: 460px) {
        margin-left: to-rem(10);
        margin-right: to-rem(10);
    }

    .technology-container {
        margin: 0 auto;
        max-width: to-rem(1200);

        @media screen and (max-width: 1439px) {
            max-width: to-rem(738);
        }

        @media screen and (max-width: 833px) {
            max-width: calc(100% - to-rem(48));
        }

        @media screen and (max-width: 768px) {
            margin: 0 to-rem(20);
        }

        @media screen and (max-width: 460px) {
            margin: 0;
            max-width: 100%;
        }

        .technology-title-container {
            margin: 0 0 to-rem(40);

            .technology-title {
                text-align: center;

                .technology-title-span {
                    color: $white;
                }

                @media screen and (max-width: 1439px) {
                    font-size: to-rem(34);
                    line-height: to-rem(40);
                }

                @media screen and (max-width: 833px) {
                    font-size: 34px; // will be changed
                    line-height: 40px; // will be changed
                }
            }
        }

        .carousel-main-container {
            margin: 0 auto;
            position: relative;

            .carousel-gradient {
                display: none;
                position: absolute;

                @media screen and (max-width: 1439px) {
                    display: inline-block;
                    background: linear-gradient(270deg,
                            #17171f 0%,
                            rgba(23 23 31 / 0%) 100%);
                    width: 154px;
                    height: 232px;
                    right: 0;
                    top: 0;
                }

                @media screen and (max-width: 834px) {
                    width: 72px;
                }
            }

            .react-multi-carousel-list {
                padding-bottom: to-rem(80);

                .react-multi-carousel-track {
                    .react-multi-carousel-item {
                        display: flex;
                        justify-content: center;
                    }
                }

                .custom-button-group {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    gap: to-rem(10);
                    right: 0;
                    margin-top: to-rem(40);
                    color: $white;

                    .button-right,
                    .button-left {
                        width: to-rem(48);
                        height: to-rem(48);
                        bottom: 0;
                        border-radius: 4px;
                        border: 1px solid $white;
                        background: none;
                        color: $white;

                        svg {
                            fill: $white;
                            width: to-rem(24);
                            height: to-rem(24);
                        }

                        @media (hover: hover) {
                            &:hover {
                                cursor: pointer;
                                color: $green;
                                border-color: $green;
                            }

                            &:hover .button-svg {
                                fill: $green;
                            }
                        }
                    }
                }
            }
        }
    }
}