@import '../../scss/variables';
@import '../../scss/functions/to-rem';

.hero-container {
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
    padding-top: to-rem(112);
    color: $white;
    gap: to-rem(40);
    max-width: to-rem(1200);
    height: to-rem(616px);

    .hero-title-picture {
        display: flex;
        align-items: center;
    }

    .hero-title-container {
        display: flex;
        gap: to-rem(40);
        flex-direction: column;
        justify-content: center;
        text-align: left;

        .hero-title {
            font-style: normal;
            color: $white;
            max-width: to-rem(683);

            h3 {
                color: $white;
            }
        }

        .hero-title-description {
            font-weight: 400;
            font-size: to-rem(16);
            line-height: to-rem(24);
            color: $white;
            max-width: to-rem(643);
        }

        .hero-title-button-container {
            display: flex;
            flex-direction: row;
            gap: to-rem(20);
        }
    }
}

@media screen and (max-width: 1439px) {
    .hero-container {
        height: to-rem(470);
        margin: 0 auto;
        padding-top: 112px;
        gap: to-rem(40);

        .hero-title-container {
            display: flex;
            gap: to-rem(20);

            .hero-title {
                max-width: to-rem(473);

                h3 {
                    font-size: to-rem(34);
                    line-height: to-rem(40);
                }
            }

            .hero-title-description {
                max-width: to-rem(453);
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .hero-container {
        margin: 0 to-rem(60);
        gap: to-rem(30);
    }
}

@media screen and (max-width: 992px) {
    .hero-container {
        height: to-rem(450);
        margin: 0 to-rem(48);
        gap: to-rem(40);
    }
}

@media screen and (max-width: 833px) {
    .hero-container {
        height: to-rem(625);
        flex-direction: column;
        position: relative;
        margin: 0 to-rem(120);
        padding: 0 0 to-rem(80);
        justify-content: flex-end;

        .hero-title-container {
            gap: to-rem(20);
            text-align: center;
            margin-top: to-rem(108);

            .hero-title {
                max-width: 100%;

                h3 {
                    font-size: to-rem(34);
                    line-height: to-rem(40);
                }
            }

            .hero-title-description {
                max-width: 100%;
                font-size: to-rem(14);
                line-height: to-rem(24);
            }

            .hero-title-button-container {
                flex-direction: column;
                gap: to-rem(20);
            }
        }

        .hero-title-picture {
            position: absolute;
            top: to-rem(80);
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            display: flex;
            justify-content: center;
        }
    }
}

@media screen and (max-width: 768px) {
    .hero-container {
        margin: 0 to-rem(100);
    }
}

@media screen and (max-width: 600px) {
    .hero-container {
        height: to-rem(645);
        margin: 0 to-rem(60);

        .hero-title-container {
            margin-top: to-rem(208);
        }
    }
}

@media screen and (max-width: 480px) {
    .hero-container {
        margin: 0 to-rem(24);

        .hero-title-container {
            margin-top: to-rem(148);
        }
    }
}
