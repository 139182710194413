@import '../../scss/variables';
@import '../../scss/functions/to-rem';
@import 'nav-burger';

.main-nav {
    background-color: transparent;
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    z-index: 3;
    transition: all 0.1s ease-in;
    padding: 10px 0;

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: to-rem(1200);
        margin: 0 auto;
        padding: 0 to-rem(44);
    }

    &__logo {
        position: relative;
        line-height: 0;
    }

    &__links {
        display: flex;
        align-items: center;

        .section {
            display: flex;
            align-items: center;
            list-style-type: none;
            padding: to-rem(0);

            li {
                padding: 0 to-rem(24);

                button {
                    font-family: Lexend, sans-serif;
                    font-size: to-rem(14);
                    line-height: to-rem(20);
                }
            }
        }
    }

    &__link {
        color: $white;
        text-decoration: none;

        &:hover {
            color: $white;
        }
    }

    .follow {
        display: none;
        opacity: 0;
        z-index: 1;

        a {
            text-decoration: none;
        }
    }

    .dropdown-and-burger-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .dropdown {
            font-family: Lexend, sans-serif;
            margin-right: to-rem(20);

            img {
                border-radius: 2px;
            }
        }
    }
}

.scrolled {
    background-color: $black90;
}

@media screen and (max-width: 992px) {
    .main-nav {
        &__links {
            .section {
                li {
                    padding: 0 to-rem(8);
                }
            }
        }
    }
}

@media screen and (max-width: 880px) {
    .main-nav {
        &__links {
            .section {
                li {
                    padding: 0 4px;
                }
            }
        }
    }
}

@media screen and (max-width: 835px) {
    .main-nav {
        .container {
            padding: 0;
            margin: 0 to-rem(24);
        }

        &__links {
            flex-direction: column;
            gap: to-rem(24);
            position: fixed;
            padding-top: to-rem(80);
            padding-bottom: to-rem(48);
            right: 0%;
            top: 0;
            left: auto;
            height: 100vh;
            width: 100vw;
            background-color: $black90;
            transition: all 0.4s ease-out;
            overflow-y: auto;

            &.collapsed {
                right: -120%;
                opacity: 0;
            }

            .border {
                height: 1px;
                width: 113px;
                background-color: $green;
                opacity: 0.5;
                margin-top: to-rem(16);
                margin-bottom: to-rem(16);
            }

            .section {
                display: flex;
                flex-direction: column;

                li {
                    display: flex;
                    flex-direction: column;

                    a {
                        text-align: center;

                        button {
                            font-family: Lexend, sans-serif;
                            font-size: to-rem(16);
                            line-height: to-rem(24);
                        }
                    }
                }
            }
        }

        &__link {
            font-size: to-rem(13);
            margin-right: to-rem(19);
        }

        .follow {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: to-rem(40);
            opacity: 1;

            .follow-title {
                line-height: to-rem(24);
                color: $gray;
            }

            .collapsed-nav {
                &__link {
                    display: flex;
                    flex-direction: row;
                    padding: to-rem(8);

                    .icon {
                        padding-right: to-rem(8);
                    }

                    .linkedin-text {
                        line-height: to-rem(24);
                        color: $white;
                    }
                }
            }
        }

        .dropdown-and-burger-wrapper {
            .dropdown {
                margin-right: to-rem(0);
            }
        }
    }
}
