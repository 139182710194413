@import '../../../scss/variables';
@import '../../../scss/functions/';
@import './mixins/';

.button {
    $this: &;
    $border-radius: 4px;
    $animation-time: 3s;

    font-family: Lexend, sans-serif;
    font-size: to-rem(16);
    align-items: center;
    border: none;
    border-radius: $border-radius;
    text-decoration: none;
    padding: to-rem(16) to-rem(24);
    position: relative;
    background: $purple;
    z-index: 1;
    color: $white;

    &__link {
        text-decoration: none;
    }

    &__border {
        align-items: center;
        position: relative;
        border-radius: $border-radius;
        background: none;
        display: flex;
        padding: 1px 1.5px;
        justify-content: center;
        overflow: hidden;

        @media (hover: hover) {
            &:hover {
                &>#{$this}__linear-background {
                    animation: circle 1s infinite;
                }
            }
        }
    }

    &__linear-background {
        aspect-ratio: 1 / 1;
        width: calc(100% + 20px);
        background-image: $button-gradient;
        position: absolute;
    }

    // hover pele
    &:hover {
        cursor: pointer;
    }

    &--primary {
        @include button-primary;
    }

    &--secondary {
        @include button-secondary;
    }

    &--nav {
        @include button-nav;
    }

    &--nav-text {
        @include button-nav-text;
    }

    &--text-button {
        @include text-button;
    }

    &--form {
        @include button-form;
    }
}